// CookieBanner.js

import React, { useState, useEffect } from 'react';
import './CookieBanner.css';

const CookieBanner = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const cookiesAccepted = localStorage.getItem('cookiesAccepted');
    if (!cookiesAccepted) {
      setIsVisible(true);
    }
  }, []);

  const handleAcceptCookies = () => {
    localStorage.setItem('cookiesAccepted', 'true');
    setIsVisible(false);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div className="cookie-banner">
      <p>
        We use cookies to ensure you get the best experience on our website. <a href="/privacy&policy">Learn more</a>
      </p>
      <button onClick={handleAcceptCookies} className="accept-button">Accept</button>
    </div>
  );
};

export default CookieBanner;
