import React from 'react';
import '../Footer/Footer.css';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faFacebook, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons';
import f1 from '../../Media/f1.jpeg'
import f2 from '../../Media/f2.jpeg'
import f3 from '../../Media/f3.jpg'
import f4 from '../../Media/f4.jpg'
import f5 from '../../Media/f5.png'
import TrustBoxWidget from '../trustpiliot/trustpiliot';

function Footer() {

  const handlePhoneClick = () => {
    // Google Ads conversion tracking for phone calls
    window.gtag('config', 'AW-16610863777/TfD8CJex_70ZEKHV1vA9', {
      'phone_conversion_number': '(509) 517-7230'
    });
  };

  

  return (
<>

<footer className="site-footer">
  
  {/* <div className="social-links">
    <a href="https://www.linkedin.com/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faLinkedin} /></a>
    <a href="https://www.facebook.com/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faFacebook} /></a>
    <a href="https://www.instagram.com/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faInstagram} /></a>
    <a href="https://twitter.com/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faTwitter} /></a>
  </div> */}
  <div className="footer-container">
    <div className="footer-section">
      <h4>Get In Touch</h4>
      {/* <p>Address: 4478 RT 27 SUITE 201 E KINGSTON</p> */}
      <p>
      <a 
          href="tel:+15095177230"
        className='phone-text-nub' 
        onClick={handlePhoneClick}
      >
        Phone: (509) 517-7230
      </a>
    </p>
    <p>      <a 
          href="tel:+18664104797"
        className='phone-text-nub' 
        onClick={handlePhoneClick}
      >
        Phone: (866) 410-4797
      </a></p>
      <p>      <a 
          href="tel:+8668843459"
        className='phone-text-nub' 
        onClick={handlePhoneClick}
      >
        Phone: (866) 884-3459
      </a></p>
      {/* <p><a href="tel:(509) 517-7230" className='phone-text-numb' onClick={() => window.gtag_report_conversion("tel:(509) 517-7230")}>Phone: (509) 517-7230</a></p> */}
      <p>Email: Support@vgroupventures.com</p>
      {/* <p>Address: Seattle, Washington</p> */}

    </div>

    <div className="footer-section">
      <h4>Quick Links</h4>
      <Link to="/">Home</Link>
      <Link to="/about">About Us</Link>
      <Link to="/contact_us">Contact Us</Link>
      <Link to="/services">Our Service </Link>

      {/* <Link to="/social_connect">Terms & Policies</Link>
      <Link to="/social_connect">Privacy Policy</Link> */}
      {/* Other company links */}
    </div>

    <div className="footer-section">
      <h4>Legal</h4>
      <Link to="/Terms&Condition">Terms & Conditions</Link>
      <Link to="/privacy&policy">Privacy Policy</Link>
      <Link to="/cancellation_policies">Cancellation And Refund Policy</Link>
    </div>

  </div>

  <div className="footer-disclaimer">
<div class="image-container">
  <img src={f1} alt="f1" className="icon-image" />
  <img src={f2} alt="f2"   className="icon-image"/>
  <img src={f3} alt="f3"   className="icon-image"/>
  <img src={f4} alt="f4"   className="icon-image"/>
  <img src={f5} alt="f5"   className="icon-image"/>
    {/* <img src="your_image_url_here" alt="Image Description" class="icon-image"> */}
</div>
<h2 class="centered-heading">Disclaimer</h2>
<p class="small-texts"> Vgroup Ventures LLC, it's registered office located at 587 155th PL NE in the city of Bellevue with zip code 98007 in the state of Washington, USA is an ARC Verified Travel Consultant under ARC Number 50631453. Vgroup Ventures LLC also holds Seller of Travel licence number 605407310 as provided by the State of Washington, USA. We have met and continue to meet the operational standards as described in ARC's Verified Travel Consultant agreement. We are available to provide customer support on all days from Monday to Sunday.</p>
<p class="small-texts"> <a   href="tel:+15095177230"  className='phone-text-nub'  onClick={() => window.gtag_report_conversion("tel:(509) 517-7230")}> (509) 517-7230</a></p>
<p class="small-texts"> <a   href="tel:+18664104797"  className='phone-text-nub'  onClick={() => window.gtag_report_conversion("tel:(509) 517-7230")}> (866) 410-4797</a></p>
{/* <p class="small-text"> <a   href="tel:+18888502719"  className='phone-text-nub'  onClick={() => window.gtag_report_conversion("tel:(509) 517-7230")}> (888) 850-2719</a></p> */}
<p class="small-texts">Email-Support@vgroupventures.com</p>
</div>


  <div className="footer-bottom">
    <p>&copy; {new Date().getFullYear()} © Copyright 2024 VGroup Ventures LLC.</p>
    <button onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
      ↑ Back to Top
    </button>
    
  </div>
</footer></>
  );
}

export default Footer;
