import React, { useState } from 'react';
import './NumberPopup.css';
import call from '../../Media/call.png'; // Phone call icon
import caller from '../../Media/Girl_cruise1.png'; // Agent icon

const NumberPopup = () => {
    const [isVisible, setIsVisible] = useState(true);

    const handleClose = () => {
        setIsVisible(false);
    }

    if (!isVisible) {
        return null;
    }

    const handlePhoneClick = () => {
        // Google Ads conversion tracking for phone calls
        window.gtag('config', 'AW-16610863777/TfD8CJex_70ZEKHV1vA9', {
          'phone_conversion_number': '(509) 517-7230'
        });
    };

    return (
        <div className="bottom-bar" onClick={() => window.gtag_report_conversion("tel:+18664104797")}>
            <div className="popup-left">
                <a href="tel:+18664104797">
                    <img src={caller} alt="Agent" className="agent-icon" />
                </a>
            </div>
            <div className="popup-content">
                <span className="small-text">
                Call Now & Get up to
                    <span className="discount-box">40% OFF</span> 
                    on Flights
                </span>
                <a href="tel:+18664104797" className="contact-numbers" onClick={handlePhoneClick}>
                    <span className="phone-number"> (866) 410-4797</span>
                </a>
            </div>
            <div className="popup-right">
                <a href="tel:+18664104797">
                    <img src={call} alt="Call Icon" className="call-icon" />
                </a>
            </div>
        </div>
    );
}

export default NumberPopup;
