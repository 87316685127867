import React, { useState } from 'react';
import '../HomePage/HomePage.css';
import trip1 from '../../Media/custom.webp';
import trip2 from '../../Media/1111.webp'; // updated to webp
import trip3 from '../../Media/111.webp'; // updated to webp
import trip4 from '../../Media/11111.webp'; // updated to webp
import trip5 from '../../Media/111111.webp'; // updated to webp
import { Link } from 'react-router-dom';
import holiday from '../../Media/holiday.webp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlane, faDollarSign, faHeadset, faShieldAlt, faShield, faAward,
  faUserGraduate, faCheckCircle, faBriefcase, faClock, faPhone
} from '@fortawesome/free-solid-svg-icons';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import BookingForm from '../Bookings/Bookings';
import PopularDestinations from '../PopularDestinations/PopularDestinations';
import WhyChooseUs from '../WhyChooseUs/WhyChooseUs';
// import vdbg from '../../Media/bg1.mp4';
import TrustBoxWidget from '../trustpiliot/trustpiliot';
import profileImage from '../../Media/profileImage.webp'; // updated to webp
import flightImage from '../../Media/flight.jpg'

import NumberPopup from '../NumberPopup/NumberPopup.jsx';
import CookieBanner from '../CookieBanner/CookieBanner.jsx';
import Navbar1 from '../Navbar/Navbar1.jsx';



export default function HomePage() {

  const [activeIndex, setActiveIndex] = useState(null);

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };


  const faqs = [
    {
      question: "How can I book a flight?",
      answer: "You can easily book a flight through our website by searching for your desired destination, selecting your preferred dates, and completing the booking process. Alternatively, you can also book flights through our customer service team via phone or email."
    },
    {
      question: "What are the accepted payment methods?",
      answer: "We accept various payment methods, including credit cards (Visa, Mastercard, American Express), debit cards, PayPal, and bank transfers. You can choose the payment option that is most convenient for you during the checkout process."
    },
    {
      question: "Can I cancel or change my booking?",
      answer: "Yes, you can cancel or change your booking, but it depends on the fare rules associated with your ticket. Some tickets may be non-refundable or have penalties for changes. Please refer to the terms and conditions of your booking or contact our customer support team for assistance."
    },
    {
      question: "Is there a customer support service available?",
      answer: "Yes, we have a dedicated customer support team available 24/7 to assist you with any inquiries or issues you may have. You can reach out to us via phone, email, or live chat for prompt assistance."
    },
    {
      question: "Are there any travel restrictions or requirements I should be aware of?",
      answer: "Travel restrictions and requirements vary depending on your destination and current global circumstances. It's essential to stay updated on any travel advisories, visa requirements, COVID-19 protocols, and health regulations imposed by authorities. We recommend checking with relevant authorities and consulting our website for the latest information before planning your trip."
    }
  ];



  const testimonials = [
    {
      id: 1,
      name: 'John Doe',
      rating: 5,
      appreciation: 'Amazing experience! The booking process was smooth and the flight was on time. Will definitely use this service again.',
      image: trip4, // Example image URL
    },
    {
      id: 2,
      name: 'Jane Smith',
      rating: 4,
      appreciation: 'Great service with helpful customer support. The only issue was a slight delay, but overall, a good experience.',
      image: trip5, // Example image URL
    },
    {
      id: 3,
      name: 'Michael Brown',
      rating: 5,
      appreciation: 'Exceptional service! Everything was well organized and the staff was very professional. Highly recommended!',
      image: trip2, // Example image URL
    },
    {
      id: 4,
      name: 'Emily White',
      rating: 4,
      appreciation: 'Very good service. Easy to book and the flight was comfortable. Will book again for my next trip.',
      image: trip3, // Example image URL
    },
  ];
  

  // Settings for the carousel
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000, // 4 seconds interval
    arrows: false, // Disable arrow navigation
  };



  const handlePhoneClick = () => {
    // Initiate phone call
    window.location.href = 'tel:+18664104797';
  };



  return (

    <>
    <BookingForm/>
    <div className='homepage'>

    {/* <div className="home-dial-us-container">
      <div>
        <p><strong>Speak to Live Travel Experts for Assistance</strong></p>
        <a 
            href="tel:+15095177230"
          className="contact-number-container-loader-loading" 
          onClick={handlePhoneClick}
        >
          <div className="image-container-call-loading">
            <img src={profileImage} alt="Customer Support" className="profile-image-loading" />
            <FontAwesomeIcon icon={faPhone} className="phone-icon-loading faa-ring animated" />
          </div>
          <span className="phone-number-loading">(509) 517-7230</span>
        </a>
      </div>
    </div> */}
      


    <section className="homepage-features">
        <h2 className="homepage-features-heading">Benefits of Booking With Us</h2>
        <div className="homepage-feature-boxes">
          <div className="homepage-feature-box">
            <div className="homepage-feature-icon">
              <FontAwesomeIcon icon={faPlane} size="3x" />
            </div>
            <h3 className="homepage-feature-heading">Best Bookings</h3>
            <p className="homepage-feature-description">Experience hassle-free booking with our top-notch service.</p>
          </div>
          <div className="homepage-feature-box">
            <div className="homepage-feature-icon">
              <FontAwesomeIcon icon={faDollarSign} size="3x" />
            </div>
            <h3 className="homepage-feature-heading">Best Price</h3>
            <p className="homepage-feature-description">Enjoy competitive pricing for your flights.</p>
          </div>
          <div className="homepage-feature-box">
            <div className="homepage-feature-icon">
              <FontAwesomeIcon icon={faHeadset} size="3x" />
            </div>
            <h3 className="homepage-feature-heading">24X7 Support</h3>
            <p className="homepage-feature-description">Get round-the-clock support whenever you need it.</p>
          </div>
          <div className="homepage-feature-box">
            <div className="homepage-feature-icon">
              <FontAwesomeIcon icon={faShieldAlt} size="3x" />
            </div>
            <h3 className="homepage-feature-heading">Trusted Payments</h3>
            <p className="homepage-feature-description">Secure and trusted payment options for your peace of mind.</p>
          </div>

        </div>

      </section>

<div>
<PopularDestinations/>

</div>
<section>
<div className="flight-reservations-assistance-container">
      <div className="flight-reservations-assistance-image-container">
        <img src={flightImage} alt="Flight Assistance" className="flight-reservations-assistance-image" />
      </div>
      <div className="flight-reservations-assistance-text-container">
        <h2 className="flight-reservations-assistance-title">Flight Reservations Assistance</h2>
        <p className="flight-reservations-assistance-text">
          When it comes to handling your travel arrangements efficiently, having quick access to important resources like the airlines reservations number can be incredibly helpful. Whether you need to make changes to your flight booking on the phone, inquire about first class flight bookings, or even deal with unexpected circumstances like flight cancellations, being able to reach out to the someone directly can make the process much smoother. Flight ticket changes can sometimes be a hassle, but with the right support from the customer service team, you can navigate through these issues with ease. In situations where flight cancellations occur, having the reservations number readily available can save you time and stress by allowing you to quickly rebook or explore other options. And for those looking to experience luxury while traveling, booking a first class flight over the phone ensures a seamless and personalized booking process, tailored to your preferences and needs.
        </p>
      </div>
    </div>
</section>

      <div className='homepage-customised-service-overview-container'>
        <section className="homepage-customised-service-overview">
          <div className="homepage-customised-service-overview-banner">
            <div className="homepage-customised-service-overview-banner-content">
              <h1 className="homepage-customised-service-overview-banner-heading">Customize Your Trip With Us</h1>
              <p>An excursion is a trip by a group of people, usually made for leisure, education, or physical purposes. It is often an adjunct to a longer journey or visit to a place, sometimes for other typically work-related purposes.</p>
              <Link to='/customise-trip' style={{ textDecoration: 'none' }}>
                <button className="homepage-customised-button">Customize Your Trip Now</button>
            </Link>
            </div>
            <div className="homepage-customised-service-overview-banner-image">
              <img src={trip1} alt="Customize Your Trip Banner" className="homepage-customised-image" />
            </div>
          </div>
        </section>
      </div>
      <WhyChooseUs/>
      <div className='page-holidays-customised-service-overview-container'>
        <section className="page-holidays-customised-service-overview">
          <div className="page-holidays-customised-service-overview-banner">
            <div className="page-holidays-customised-service-overview-banner-content">
              <h1 className="page-holidays-customised-service-overview-banner-heading">A Great Way To Start Vacation</h1>
              <p>Book flight ticket at lowest price. Get cheap flights for domestic & international destinations with best offers & discounts on air tickets. Book & save big on every flight booking.</p>
              {/* <button className="page-holidays-customised-button">Plan Your Holidays With Us</button> */}
            </div>
            <div className="page-holidays-customised-service-overview-banner-image">
              <img src={holiday} alt="Customize Your Trip Banner" className="page-holidays-customised-image" />
            </div>
          </div>
        </section>
      </div>







      <div className="homepage-features">

      <section className="home-page-our-promises">
        <h2 className="home-page-our-promises-heading">Our Strengths</h2>
        <div className="home-page-our-promises-boxes">
          <div className="home-page-our-promises-box">
            <div className="home-page-our-promises-icon">
              <FontAwesomeIcon icon={faAward} size="3x" />
            </div>
            <h3 className="home-page-our-promises-heading">Award Winning Company</h3>
            <p className="home-page-our-promises-description">We stand as one of the premier air ticket and hotel booking companies, renowned for our exceptional service and commitment to excellence.</p>
          </div>
          <div className="home-page-our-promises-box">
            <div className="home-page-our-promises-icon">
              <FontAwesomeIcon icon={faUserGraduate} size="3x" />
            </div>
            <h3 className="home-page-our-promises-heading">Expert Guidance by Our Travel Experts</h3>
            <p className="home-page-our-promises-description">Receive expert guidance from our team of seasoned travel experts who are dedicated to ensuring your journey is nothing short of extraordinary.</p>
          </div>
          <div className="home-page-our-promises-box">
            <div className="home-page-our-promises-icon">
              <FontAwesomeIcon icon={faCheckCircle} size="3x" />
            </div>
            <h3 className="home-page-our-promises-heading">Immediate Booking Confirmation</h3>
            <p className="home-page-our-promises-description">Experience the convenience of immediate booking confirmation, ensuring instant peace of mind for your travel plans.</p>
          </div>
          <div className="home-page-our-promises-box">
            <div className="home-page-our-promises-icon">
              <FontAwesomeIcon icon={faShield} size="3x" />
            </div>
            <h3 className="home-page-our-promises-heading">Travel Protection</h3>
            <p className="home-page-our-promises-description">We safeguard not only your travel investment and belongings but, most crucially, you.</p>
          </div>
          <div className="home-page-our-promises-box">
            <div className="home-page-our-promises-icon">
              <FontAwesomeIcon icon={faBriefcase} size="3x" />
            </div>
            <h3 className="home-page-our-promises-heading">Special Baggage Protection</h3>
            <p className="home-page-our-promises-description">Enhance your trip with our Baggage Protection: quickly retrieve delayed bags or receive a full refund.</p>
          </div>
          <div className="home-page-our-promises-box">
            <div className="home-page-our-promises-icon">
              <FontAwesomeIcon icon={faClock} size="3x" />
            </div>
            <h3 className="home-page-our-promises-heading">24-Hour Cancellation</h3>
            <p className="home-page-our-promises-description">Enjoy flexibility with our 24-hour cancellation policy, providing you the freedom to adapt your plans when needed.</p>
          </div>
        </div>
      </section>

      </div>









{/* 

      <div className="testimonials-section">
        <h2 className="testimonials-heading">TESTIMONIALS</h2>
        <div className="testimonials-carousel">
          <Slider {...settings}>
            {testimonials.map((testimonial) => (
              <div key={testimonial.id} className="testimonial-card">
                <div className="testimonial-image">
                  <img src={testimonial.image} alt={testimonial.name} />
                </div>
                <h3 className="testimonial-name">{testimonial.name}</h3>
                <div className="testimonial-rating">
                  {Array.from({ length: testimonial.rating }, (_, index) => (
                    <span key={index} className="star-icon">&#9733;</span>
                  ))}
                </div>
                <p className="testimonial-message">{testimonial.appreciation}</p>
              </div>
            ))}
          </Slider>
        </div>
      </div>

     */}

      <section className="homepage-faqs-section-mendat">
        <h2 className="homepage-faqs-section-heading-mendat">Frequently Asked Questions</h2>
        {faqs.map((faq, index) => (
          <div className="homepage-faq-mendat" key={index}>
            <button
              className={`homepage-faq-question-mendat ${activeIndex === index ? 'active' : ''}`}
              onClick={() => handleToggle(index)}
            >
              {faq.question}
            </button>
            <p className={`homepage-faq-answer-mendat ${activeIndex === index ? 'active' : ''}`}>
              {faq.answer}
            </p>
          </div>
        ))}
      </section>
<TrustBoxWidget/>
{/* <NumberPopup/> */}
<CookieBanner/>

    </div>
    </>
  )
}
