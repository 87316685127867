// CustomerServicePage.js
import React from 'react';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import { FaPhone, FaEnvelope, FaComments, FaClock, FaUserCheck, FaHandshake, FaGlobe, FaCheckCircle, FaPlane, FaCog, FaMapMarkedAlt, FaDesktop, FaSuitcase, FaHeart, FaHandsHelping } from 'react-icons/fa';
import './CustomerService.css';
import NumberPopup from '../NumberPopup/NumberPopup';
import Bookingslanding from '../Bookings/Bookingslanding.jsx';
import image from '../../Media/136431.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';

import '@fortawesome/fontawesome-free/css/all.min.css';


const CustomerServicePage = () => {

  const handlePhoneClick = () => {
    // Initiate phone call
    window.location.href = 'tel:+18664104797';
  };

  return (
    <div>
            <div className="landing-page-1-container">
        <div className="landing-page-1-card">
          <h2 className="landing-page-1-title">Airline Reservation Desk </h2>
          <h2 className="landing-page-1-title">24/7 Helpline ( Toll-free )</h2>
          <img
            src={image} 
            alt="Flight Attendant"
            className="landing-page-1-image"
          />
          <button  className="landing-page-1-cta-button"  href="tel:+18664104797" onClick={handlePhoneClick} >Booking, Changes & Cancellation</button>
          <div className="landing-page-1-call-info">
            <p>No Hold – Call Answered In 5 Sec</p>
            <a href="tel:+18664104797" className="landing-page-1-phone-number">
              <div className="landing-page-1-phone-box">
                {/* Animated Font Awesome Icon */}
                <FontAwesomeIcon
                  icon={faPhone}
                  className="landing-page-1-phone-icon fa-bounce" // Add bounce animation
                /> (866) 410-4797
              </div>
            </a>
            <p>24/7 Helpline</p>
          </div>
        </div>
      </div>
      <Bookingslanding />
      <Container className="customer-service-page">
        <h1 className="customer-service-title text-center mb-4">24/7 Customer Service at Vgroup Ventures LLC</h1>
        <p className="customer-service-subtitle text-center mb-5">We're Here for You, Anytime, Anywhere</p>
        
        <Row className="justify-content-center mb-5">
          <Col md={4} className="mb-4">
            <Card className="customer-service-card">
              <Card.Body>
                <FaClock className="customer-service-icon" />
                <Card.Title className="customer-service-card-title">Round-the-Clock Support</Card.Title>
                <Card.Text>
                  Our team is available 24/7, providing you with assistance whenever you need it, regardless of your time zone or travel schedule.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col md={4} className="mb-4">
            <Card className="customer-service-card">
              <Card.Body>
                <FaUserCheck className="customer-service-icon" />
                <Card.Title className="customer-service-card-title">Expert Assistance</Card.Title>
                <Card.Text>
                  Our highly trained customer service representatives are knowledgeable and experienced, ready to help you with all your travel needs.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col md={4} className="mb-4">
            <Card className="customer-service-card">
              <Card.Body>
                <FaHandshake className="customer-service-icon" />
                <Card.Title className="customer-service-card-title">Personalized Service</Card.Title>
                <Card.Text>
                  We take the time to understand your unique travel requirements and preferences, offering tailored solutions to meet your needs.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col md={4} className="mb-4">
            <Card className="customer-service-card">
              <Card.Body>
                <FaGlobe className="customer-service-icon" />
                <Card.Title className="customer-service-card-title">Multi-Channel Support</Card.Title>
                <Card.Text>
                  Contact us through multiple channels, including phone, email, live chat, and social media. Choose the method that works best for you.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col md={4} className="mb-4">
            <Card className="customer-service-card">
              <Card.Body>
                <FaCheckCircle className="customer-service-icon" />
                <Card.Title className="customer-service-card-title">Quick Response Time</Card.Title>
                <Card.Text>
                  We prioritize your inquiries and aim to resolve issues promptly, ensuring minimal disruption to your travel plans.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col md={4} className="mb-4">
            <Card className="customer-service-card">
              <Card.Body>
                <FaCheckCircle className="customer-service-icon" />
                <Card.Title className="customer-service-card-title">Transparent Policies</Card.Title>
                <Card.Text>
                  We believe in clear and open communication. You'll always be informed about policies, fees, and any other relevant information.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <section className="customer-service-contact mb-5">
          <Container>
            <h2 className="text-center mb-4">How to Reach Us</h2>
            <Row className="text-center">
              <Col md={4}>
                <FaPhone size={50} className="mb-3 customer-service-contact-icon" />
                <h3>Phone Support</h3>
                <p>Speak directly with our customer service representatives for immediate assistance. Our hotline is available 24/7 to address your concerns.</p>
              </Col>
              <Col md={4}>
                <FaEnvelope size={50} className="mb-3 customer-service-contact-icon" />
                <h3>Email Support</h3>
                <p>Send us an email detailing your query or issue. Our team will respond promptly with the information or assistance you need.</p>
              </Col>
              <Col md={4}>
                <FaComments size={50} className="mb-3 customer-service-contact-icon" />
                <h3>Live Chat</h3>
                <p>Use our live chat feature on the website for real-time support from our customer service team.</p>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="customer-service-section mb-5">
          <h2>Services We Offer</h2>
          <Row className="justify-content-center">
            <Col md={4} className="mb-4">
              <Card className="customer-service-card">
                <Card.Body>
                  <FaPlane className="customer-service-icon" />
                  <Card.Title className="customer-service-card-title">Flight Booking Assistance</Card.Title>
                  <Card.Text>
                    Get help with booking flights, finding the best deals, and understanding fare options.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col md={4} className="mb-4">
              <Card className="customer-service-card">
                <Card.Body>
                  <FaCog className="customer-service-icon" />
                  <Card.Title className="customer-service-card-title">Booking Modifications</Card.Title>
                  <Card.Text>
                    Make changes to your existing bookings, including flight changes, upgrades, and cancellations.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col md={4} className="mb-4">
              <Card className="customer-service-card">
                <Card.Body>
                  <FaMapMarkedAlt className="customer-service-icon" />
                  <Card.Title className="customer-service-card-title">Travel Information</Card.Title>
                  <Card.Text>
                    Receive up-to-date information on travel restrictions, visa requirements, and destination guidelines.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col md={4} className="mb-4">
              <Card className="customer-service-card">
                <Card.Body>
                  <FaDesktop className="customer-service-icon" />
                  <Card.Title className="customer-service-card-title">Technical Support</Card.Title>
                  <Card.Text>
                    Get assistance with any technical issues you encounter on our website or mobile app.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col md={4} className="mb-4">
              <Card className="customer-service-card">
                <Card.Body>
                  <FaSuitcase className="customer-service-icon" />
                  <Card.Title className="customer-service-card-title">Special Requests</Card.Title>
                  <Card.Text>
                    Request special services such as meal preferences, wheelchair assistance, or additional baggage.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </section>

        <section className="customer-service-section mb-5">
          <h2 className="text-center mb-4">Our Commitment to You</h2>
          <Row className="justify-content-center">
            <Col md={3} className="mb-4">
              <Card className="customer-service-card">
                <Card.Body>
                  <FaCheckCircle className="customer-service-icon" />
                  <Card.Title className="customer-service-card-title">Customer Satisfaction</Card.Title>
                  <Card.Text>
                    Your satisfaction is our top priority. We strive to provide exceptional service and ensure a positive experience every time you interact with us.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col md={3} className="mb-4">
              <Card className="customer-service-card">
                <Card.Body>
                  <FaHeart className="customer-service-icon" />
                  <Card.Title className="customer-service-card-title">Personalized Care</Card.Title>
                  <Card.Text>
                    We value your unique needs and preferences, offering personalized solutions to enhance your experience.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col md={3} className="mb-4">
              <Card className="customer-service-card">
                <Card.Body>
                  <FaHandsHelping className="customer-service-icon" />
                  <Card.Title className="customer-service-card-title">Community Support</Card.Title>
                  <Card.Text>
                    We are committed to giving back to the community and supporting causes that matter to our customers and employees.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col md={3} className="mb-4">
              <Card className="customer-service-card">
                <Card.Body>
                  <FaCheckCircle className="customer-service-icon" />
                  <Card.Title className="customer-service-card-title">Transparency</Card.Title>
                  <Card.Text>
                    We maintain open and honest communication, keeping you informed every step of the way.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </section>

        {/* <section className="contact-us-section mb-5">
          <h2 className="text-center mb-4">Contact Us</h2>
          <Row className="justify-content-center">
            <Col md={4}>
              <div className="customer-service-contact-info text-center">
                <FaPhone size={40} className="mb-2" />
                <h3>Phone</h3>
                <p>(509) 517-7230</p>
              </div>
            </Col>
            <Col md={4}>
              <div className="customer-service-contact-info text-center">
                <FaEnvelope size={40} className="mb-2" />
                <h3>Email</h3>
                <p>support@vgroupventures.com</p>
              </div>
            </Col>
            <Col md={4}>
              <div className="customer-service-contact-info text-center">
                <FaComments size={40} className="mb-2" />
                <h3>Live Chat</h3>
                <Button variant="primary">Start Chat</Button>
              </div>
            </Col>
          </Row>
        </section> */}

        <section className="new-content-section mb-5">
          <h2 className="text-center mb-4">24/7 Customer Service with VGroup Ventures</h2>
          <p>Welcome to VGroup Ventures, where customer satisfaction is at the heart of everything we do. Our dedicated 24/7 customer service team is committed to providing exceptional support across all aspects of your travel journey, ensuring a seamless experience from booking to arrival.</p>

          <h3>Comprehensive Support</h3>
          <p>At VGroup Ventures, our knowledgeable agents are available around the clock to assist you with a wide range of travel services:</p>
          <ul>
            <li><strong>Information Services:</strong> Have questions about destinations, travel restrictions, or airline policies? Our agents provide accurate and up-to-date information to help you plan your trip with confidence.</li>
            <li><strong>Booking Assistance:</strong> Need to book a flight or make travel arrangements? Our team guides you through the booking process, offering personalized recommendations and ensuring you find the best flights, accommodations, and travel packages.</li>
            <li><strong>Flight Changes and Cancellations:</strong> Plans change? Our agents make it easy to modify or cancel your flight bookings. Whether you need to change dates, upgrade your seat to first or business class, or explore refund options, we're here to assist.</li>
            <li><strong>Special Requests and Customized Itineraries:</strong> Have specific preferences for your travel plans? Our team can tailor your itinerary to include special accommodations, activities, or unique experiences, ensuring your trip is exactly as you envision.</li>
          </ul>

          <h3>Personalized Service</h3>
          <p>When you contact our 24/7 customer service, you'll speak directly to a friendly and experienced agent who understands your needs. Whether you prefer to reach us by phone, email, or through our website, we're dedicated to providing prompt and effective assistance tailored to your individual requirements.</p>

          <h3>Secure and Reliable</h3>
          <p>Your privacy and security are paramount. Our secure booking platform and stringent privacy policies ensure that your personal information and travel details are protected throughout every interaction.</p>

          <h3>Contact Us</h3>
          <p>Have a question or need assistance? Our 24/7 customer service team is here to help. Contact us today via our customer service phone number, airlines contact number, or visit our website to connect with a travel expert who can assist you with any aspect of your travel plans.</p>
          <p>Experience the dedication and reliability of VGroup Ventures' customer service. We're here around the clock, ready to make your travel dreams a reality with personalized support and expert guidance.</p>

          <div className="text-center mt-4">
            <Button variant="primary" onClick={handlePhoneClick}>Contact Customer Service</Button>
          </div>
        </section>
      </Container>

      <NumberPopup />
    </div>
  );
};

export default CustomerServicePage;
